import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Typography,
  Paper,
  Button,
  Snackbar,
  Alert,
  TextField,
  Switch,
  FormControlLabel,
} from "@mui/material";
import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";
//import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';
import Header from "../Header";

const Estoque = () => {
  const [estoque, setEstoque] = useState([]);
  const [copos, setCopos] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [mensagemSnackbar, setMensagemSnackbar] = useState("");
  const [severitySnackbar, setSeveritySnackbar] = useState("");
  //const authHeader = useAuthHeader();

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const getEstoque = async () => {
    const response = await axios.get(
      "https://acaimaniadelivery.com:3001/adicionais",
      {
        /* headers: {
				Authorization: authHeader,
			}, */
      }
    );
    setEstoque(response.data);
  };

  const getCopo = async () => {
    const response = await axios.get(
      "https://acaimaniadelivery.com:3001/copos"
    );
    setCopos(response.data);
  };

  const handleUpdateItem = async (item) => {
    await axios.put("https://acaimaniadelivery.com:3001/adicionais", item, {
      /* headers: {
				Authorization: authHeader,
			}, */
    });
    getEstoque();
    setMensagemSnackbar("Item atualizado!");
    setSeveritySnackbar("success");
    setSnackbarOpen(true);
  };

  const handleUpdateCopo = async (copos) => {
    await axios.put("https://acaimaniadelivery.com:3001/copos", copos);
    getCopo();
    setMensagemSnackbar("Copo atualizado!");
    setSeveritySnackbar("success");
    setSnackbarOpen(true);
  };

  useEffect(() => {
    getEstoque();
    getCopo();
  }, []);

  return (
    <>
      <Header />
      <Container sx={{ marginTop: "2rem" }}>
        <Grid container justifyContent="center" spacing={2}>
          <Container
            maxWidth="md"
            component={Paper}
            elevation={4}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "2rem",
              marginTop: "4rem",
            }}
          >
            <Typography variant="h5" gutterBottom color="purple">
              Estoque
            </Typography>
            {estoque.map((item) => (
              <Container
                sx={{ marginTop: "1rem", padding: "1rem" }}
                component={Paper}
                elevation={3}
                key={item.id}
              >
                <Formik
                  initialValues={{
                    nome: item.nome,
                    preco: item.preco,
                    disponivel: item.disponivel,
                  }}
                  validationSchema={Yup.object({
                    nome: Yup.string().required("Nome é obrigatório"),
                    preco: Yup.number().required("Preço é obrigatório"),
                    disponivel: Yup.boolean().required(
                      "Disponibilidade é obrigatória"
                    ),
                  })}
                  onSubmit={(values) => {
                    handleUpdateItem({
                      ...item,
                      ...values,
                    });
                  }}
                >
                  {(formik) => (
                    <form onSubmit={formik.handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            label="Nome"
                            {...formik.getFieldProps("nome")}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            label="Preço"
                            type="number"
                            {...formik.getFieldProps("preco")}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={formik.values.disponivel}
                                {...formik.getFieldProps("disponivel")}
                                color="secondary"
                              />
                            }
                            label="Habilitado"
                          />
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: "right" }}>
                          <Button
                            variant="contained"
                            color="secondary"
                            type="submit"
                          >
                            Atualizar
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </Formik>
              </Container>
            ))}
            {copos.map((copo) => (
              <Container
                sx={{ marginTop: "1rem", padding: "1rem" }}
                component={Paper}
                elevation={3}
                key={copo.id}
              >
                <Formik
                  initialValues={{
                    tamanho: copo.tamanho,
                    preco: copo.preco,
                    //disponivel: copo.disponivel,
                  }}
                  validationSchema={Yup.object({
                    tamanho: Yup.string().required("Tamanho é obrigatório"),
                    preco: Yup.number().required("Preço é obrigatório"),
                    /*disponivel: Yup.boolean().required(
											'Disponibilidade é obrigatória'
										),*/
                  })}
                  onSubmit={(values) => {
                    handleUpdateCopo({
                      ...copo,
                      ...values,
                    });
                  }}
                >
                  {(formik) => (
                    <form onSubmit={formik.handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            label="Tamanho"
                            {...formik.getFieldProps("tamanho")}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            label="Preço"
                            type="number"
                            {...formik.getFieldProps("preco")}
                          />
                        </Grid>
                        {/*<Grid
													item
													xs={12}
												>
													<FormControlLabel
														control={
															<Switch
																checked={
																	formik
																		.values
																		.disponivel
																}
																{...formik.getFieldProps(
																	'disponivel'
																)}
																color='secondary'
															/>
														}
														label='Habilitado'
													/>
												</Grid>*/}
                        <Grid item xs={12} sx={{ textAlign: "right" }}>
                          <Button
                            variant="contained"
                            color="secondary"
                            type="submit"
                          >
                            Atualizar
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </Formik>
              </Container>
            ))}
          </Container>
        </Grid>
      </Container>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={severitySnackbar}>
          {mensagemSnackbar}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Estoque;
