import { AppBar, Box, Toolbar, Typography, Button, Grid } from "@mui/material";
import UserIcon from "@mui/icons-material/AccountCircle";
import LogoutButton from "./LogoutButton";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();

  const handleNavigateToPedidos = () => {
    navigate("/");
  };

  const handleNavigateToRelatorio = () => {
    navigate("/relatorio");
  };

  const handleNavigateToEstoque = () => {
    navigate("/estoque");
  };

  return (
    <Box>
      <AppBar position="absolute" component="nav" color="secondary">
        <Toolbar>
          <UserIcon fontSize="large" />
          <Typography variant="h6" sx={{ flexGrow: 1 }}></Typography>
          <Grid
            container
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Button color="inherit" onClick={handleNavigateToPedidos}>
              Pedidos
            </Button>
            <Button color="inherit" onClick={handleNavigateToRelatorio}>
              Relatório
            </Button>
            <Button color="inherit" onClick={handleNavigateToEstoque}>
              Estoque
            </Button>
          </Grid>
          <LogoutButton />
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
