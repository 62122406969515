import {
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
  Collapse,
  Snackbar,
  Alert,
} from "@mui/material";
import { useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import { useNavigate } from "react-router-dom";

const validationSchema = yup.object({
  nomeFuncionario: yup.string().required("Nome necessário."),
  senhaFuncionario: yup.string().required("Senha necessária."),
});

const Login = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [SnackbarOpen, setSnackbarOpen] = useState(false);
  const [SnackbarMessage, setSnackbarMessage] = useState();

  const signIn = useSignIn();
  const navigate = useNavigate();

  const containerStyle = {
    py: 0,
    maxWidth: 500,
    borderRadius: 2,
    border: "1px",
    borderColor: "divider",
    maxHeight: 385,
    marginTop: "5rem",
  };

  const allign = {
    display: "flex",
    alignItems: "center",
    marginBottom: "1rem",
  };

  const allign2 = {
    display: "flex",
    alignItems: "center",
    marginBottom: "1rem",
    justifyContent: "center",
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const toggleForm = () => {
    setIsLogin(!isLogin);
  };

  const formik = useFormik({
    initialValues: {
      nomeFuncionario: "",
      senhaFuncionario: "",
    },

    validationSchema: validationSchema,

    onSubmit: async (values) => {
      console.log(values);
      try {
        const response = await axios.get(
          `https://acaimaniadelivery.com:3001/funcionarios/${formik.values.nomeFuncionario}/${formik.values.senhaFuncionario}`
        );
        console.log(response.data);

        signIn({
          auth: {
            token: response.data.token,
            type: "Bearer",
          },
          userState: {
            name: formik.values.nomeFuncionario,
          },
        });

        navigate("/");
      } catch (error) {
        console.log(error.response.data.mensagem);
        setSnackbarMessage(error.response.data.mensagem);
        setSnackbarOpen(true);
      }
    },
  });

  return (
    <>
      <Grid
        container
        spacing={2}
        style={{ position: "absolute", top: 0, height: "100%" }}
      >
        <Container style={containerStyle} component={Paper}>
          <Collapse in={isLogin}>
            <Typography
              variant="h4"
              my="1rem"
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Entrar
            </Typography>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <form onSubmit={formik.handleSubmit}>
                <div style={allign}>
                  <TextField
                    id="nomeFuncionario"
                    label="Funcionário:"
                    name="nomeFuncionario"
                    variant="outlined"
                    value={formik.values.nomeFuncionario}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.nomeFuncionario &&
                      Boolean(formik.errors.nomeFuncionario)
                    }
                    helperText={
                      formik.touched.nomeFuncionario &&
                      formik.errors.nomeFuncionario
                    }
                  />
                </div>
                <div style={allign}>
                  <TextField
                    id="senhaFuncionario"
                    label="Senha:"
                    name="senhaFuncionario"
                    variant="outlined"
                    type="password"
                    value={formik.values.senhaFuncionario}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.senhaFuncionario &&
                      Boolean(formik.errors.senhaFuncionario)
                    }
                    helperText={
                      formik.touched.senhaFuncionario &&
                      formik.errors.senhaFuncionario
                    }
                  />
                </div>
                <div style={allign}>
                  <Button variant="contained" color="secondary" type="submit">
                    Entrar
                  </Button>
                </div>
              </form>
            </div>
          </Collapse>

          {/*<Collapse in={!isLogin}>
					<div>
						<Typography
							variant='h4'
							my='1rem'
							sx={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							Registro
						</Typography>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								flexDirection: 'column',
								justifyContent: 'center',
							}}
						>
							<div style={allign}>
								<TextField
									required
									id='outlined-basic'
									label='Usuário'
									variant='outlined'
								/>
							</div>
							<div style={allign}>
								<TextField
									required
									id='outlined-basic'
									label='Senha'
									variant='outlined'
									type='password'
								/>
							</div>
							<div style={allign}>
								<TextField
									required
									id='outlined-basic'
									label='Confirme sua senha'
									variant='outlined'
									type='password'
								/>
							</div>
							<div style={allign}>
								<Button
									variant='contained'
									color='secondary'
								>
									Registrar
								</Button>
							</div>
						</div>
					</div>
						</Collapse>*/}
          <Container>
            <div style={allign2}>
              {/*<Button
							variant='text'
							onClick={toggleForm}
							color='secondary'
						>
							{isLogin
								? 'Registre um novo funcionário!'
								: 'Entre com sua conta'}
							</Button>*/}
            </div>
          </Container>
        </Container>
      </Grid>
      <Snackbar
        open={SnackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="error">
          {SnackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Login;
