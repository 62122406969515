import { BrowserRouter, Route, Routes } from 'react-router-dom';
import createStore from 'react-auth-kit/createStore';
import AuthProvider from 'react-auth-kit';
import RequireAuth from '@auth-kit/react-router/RequireAuth';
import Pedido from '../components/Pedido';
import Login from '../components/login/Login';
import Relatorio from '../components/relatorio/Relatorio';
import Estoque from '../components/estoque/Estoque';

const Router = () => {
	const store = createStore({
		authName: '_auth',
		authType: 'cookie',
		cookieDomain: window.location.hostname,
		cookieSecure: false,
	});

	return (
		<AuthProvider store={store}>
			<BrowserRouter>
				<Routes>
					<Route
						path='/'
						element={
							<RequireAuth fallbackPath={'/login'}>
								<Pedido />
							</RequireAuth>
						}
					/>
					<Route
						path='login'
						element={<Login />}
					/>
					<Route
						path='relatorio'
						element={<Relatorio />}
					/>
					<Route
						path='/estoque'
						element={<Estoque />}
					/>
				</Routes>
			</BrowserRouter>
		</AuthProvider>
	);
};

export default Router;
