import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Typography,
  Paper,
  Snackbar,
  Alert,
  TableCell,
  TableContainer,
  TableBody,
  TableRow,
  Table,
} from "@mui/material";
import axios from "axios";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import Header from "../Header";

const Relatorio = () => {
  const [clientes, setClientes] = useState([]);
  const authHeader = useAuthHeader();
  const [contadorDiario, setContadorDiario] = useState(0);
  const [totalEntregas, setTotalEntregas] = useState(0);
  const [totalVendasPorTamanho, setTotalVendasPorTamanho] = useState({});
  const [adicionaisMaisPedidos, setAdicionaisMaisPedidos] = useState({});
  const [formaPagamentoMaisUtilizada, setFormaPagamentoMaisUtilizada] =
    useState("");
  const [horarioPico, setHorarioPico] = useState("");
  const [ticketMedio, setTicketMedio] = useState(0);

  const getClientes = async () => {
    try {
      const response = await axios.get(
        "https://acaimaniadelivery.com:3001/clientes",
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );
      setClientes(response.data);
    } catch (error) {
      console.error("Erro ao obter clientes:", error);
    }
  };

  useEffect(() => {
    getClientes();
  }, []);

  useEffect(() => {
    const totalTeleEntrega = clientes.reduce((accumulator, cliente) => {
      if (cliente.tipoEntrega === "Entrega") {
        return accumulator + 1;
      }
      return accumulator;
    }, 0);
    setContadorDiario(totalTeleEntrega);
  }, [clientes]);

  useEffect(() => {
    const totalEntregasCalculado = clientes.reduce((accumulator, cliente) => {
      if (cliente.tipoEntrega === "Entrega") {
        return accumulator + cliente.valorTotal;
      }
      return accumulator;
    }, 0);
    setTotalEntregas(totalEntregasCalculado);
  }, [clientes]);

  useEffect(() => {
    const totalVendasPorTamanhoCalculado = clientes.reduce(
      (accumulator, cliente) => {
        const tamanho = cliente.pedidos[0].tamanhoAcai;
        accumulator[tamanho] = (accumulator[tamanho] || 0) + cliente.valorTotal;
        return accumulator;
      },
      {}
    );
    setTotalVendasPorTamanho(totalVendasPorTamanhoCalculado);
  }, [clientes]);

  useEffect(() => {
    const adicionaisMaisPedidosCalculado = clientes.reduce(
      (accumulator, cliente) => {
        cliente.pedidos.forEach((pedido) => {
          if (pedido.adicionais) {
            const adicionais = pedido.adicionais.split(", ");
            adicionais.forEach((adicional) => {
              accumulator[adicional] = (accumulator[adicional] || 0) + 1;
            });
          }
        });
        return accumulator;
      },
      {}
    );
    setAdicionaisMaisPedidos(adicionaisMaisPedidosCalculado);
  }, [clientes]);

  useEffect(() => {
    if (clientes.length === 0) return; // Verifica se o array de clientes está vazio

    const formasPagamento = clientes.map((cliente) => cliente.tipoPagamento);

    // Verifica se há formas de pagamento registradas
    if (formasPagamento.length === 0) return;

    const formaPagamentoMaisUtilizadaCalculada = formasPagamento.reduce(
      (accumulator, formaPagamento) => {
        accumulator[formaPagamento] = (accumulator[formaPagamento] || 0) + 1;
        return accumulator;
      },
      {}
    );

    const formaPagamentoMaisUtilizada = Object.keys(
      formaPagamentoMaisUtilizadaCalculada
    ).reduce((a, b) =>
      formaPagamentoMaisUtilizadaCalculada[a] >
      formaPagamentoMaisUtilizadaCalculada[b]
        ? a
        : b
    );
    setFormaPagamentoMaisUtilizada(formaPagamentoMaisUtilizada);
  }, [clientes]);

  useEffect(() => {
    if (clientes.length === 0) return; // Verifica se o array de clientes está vazio

    const horasPedidos = clientes.map((cliente) =>
      new Date(cliente.dataHoraPedido).getHours()
    );

    // Verifica se há horas de pedidos registradas
    if (horasPedidos.length === 0) return;

    const horarioPicoCalculado = horasPedidos.reduce((accumulator, hora) => {
      accumulator[hora] = (accumulator[hora] || 0) + 1;
      return accumulator;
    }, {});
    const horarioPico = Object.keys(horarioPicoCalculado).reduce((a, b) =>
      horarioPicoCalculado[a] > horarioPicoCalculado[b] ? a : b
    );
    setHorarioPico(horarioPico);
  }, [clientes]);

  useEffect(() => {
    const totalVendas = clientes.reduce(
      (accumulator, cliente) => accumulator + cliente.valorTotal,
      0
    );
    const ticketMedioCalculado = totalVendas / clientes.length;
    setTicketMedio(ticketMedioCalculado);
  }, [clientes]);

  return (
    <>
      <Header />
      <Container sx={{ marginTop: "6rem" }}>
        <Grid container justifyContent="center" spacing={2}>
          <Container
            maxWidth="md"
            component={Paper}
            elevation={4}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "2rem",
            }}
          >
            <Typography variant="h5" color="purple" gutterBottom>
              Relatório diário de vendas
            </Typography>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <TableCell>Total de tele-entregas</TableCell>
                    <TableCell align="right">{contadorDiario}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Valor total de entregas</TableCell>
                    <TableCell align="right">R${totalEntregas}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total de vendas por tamanho do copo</TableCell>
                    <TableCell align="right">
                      <TableContainer component={Paper}>
                        <Table aria-label="size sales table">
                          <TableBody>
                            {Object.entries(totalVendasPorTamanho).map(
                              ([tamanho, total]) => (
                                <TableRow key={tamanho}>
                                  <TableCell component="th" scope="row">
                                    {tamanho}
                                  </TableCell>
                                  <TableCell align="right">R${total}</TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Adicionais mais pedidos</TableCell>
                    <TableCell align="right">
                      <TableContainer component={Paper}>
                        <Table aria-label="top addons table">
                          <TableBody>
                            {Object.entries(adicionaisMaisPedidos)
                              .sort(([, aCount], [, bCount]) => bCount - aCount)
                              .map(([adicional, count], index) => (
                                <TableRow key={index}>
                                  <TableCell component="th" scope="row">
                                    {adicional}
                                  </TableCell>
                                  <TableCell align="right">{count}</TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Forma de pagamento mais utilizada</TableCell>
                    <TableCell align="right">
                      {formaPagamentoMaisUtilizada}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Ticket médio</TableCell>
                    <TableCell align="right">
                      R${ticketMedio.toFixed(2)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Container>
        </Grid>
      </Container>
    </>
  );
};

export default Relatorio;
