import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

const LogoutButton = () => {
	const signOut = useSignOut();
	const navigate = useNavigate();

	const handleLogout = () => {
		signOut();
		navigate('/login');
	};

	return (
		<Button
			color='inherit'
			onClick={handleLogout}
		>
			Sair
		</Button>
	);
};

export default LogoutButton;
